<template>
  <div class="homePage">
    <el-container class="normalCon">
      <el-header>
        <div class="header">
          <div class="header_title">佛山市灏海餐饮管理有限公司</div>
        </div>
      </el-header>
      <el-main>
        <div class="shopMain">
          <div class="leftShop">
            <ul>
              <li
                v-for="(menuItem, menuIndex) in menuList"
                :key="menuIndex"
                @click="menuChange(menuItem.id)"
                :class="{active: activeMenu === menuItem.id}"
              >{{ menuItem.name }}</li>
            </ul>
          </div>
          <div class="shopContainer">
            <div class="shopGoodList" v-for="(item, index) in goodsList" :key="index">
              <div class="leftImg" @click="goodsClick(item)">
                <img v-if="item.imgUrl" :src="item.imgUrl" alt="">
                <img v-else src="../assets/nodata.png" alt="">
              </div>
              <div class="rightValue">
                <div class="tilName" @click="goodsClick(item)">{{ item.name }}</div>
                <div class="priceList">
                  <div class="leftPrice" @click="goodsClick(item)">￥{{ item.price }}</div>
                  <div class="chooseBtn">
                    <div class="insideNum">库存: {{ item.qty }}</div>
                    <div class="clearNum" v-if="item.qty === 0">已售罄</div>
                    <div class="btnPart" v-else>
                      <img src="../assets/shopcart.png" @click="addOneNum(item)" class="cartLogo" v-if="item.count === 0" alt="">
                      <div class="btnGroup" v-else>
                        <div class="btnSubtraction" @click="subtractNum(item)">
                          <img src="../assets/subtract_disable.png" v-if="item.count === 1" alt="">
                          <img src="../assets/subtract.png" v-else alt="">
                        </div>
                        <span class="goodsNum">{{ item.count }}</span>
                        <div class="btnPlus" @click="addOneNum(item)">
                          <img src="../assets/plus.png" alt="">
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </el-main>
      <el-footer>
        <footer-part ref="footerPart" />
      </el-footer>
    </el-container>
    <goods-dialog
      v-if="goodsVisible"
      ref="goods"
    />
  </div>
</template>
<script>
import moment from "moment";
import footerPart from "@/components/footer";
import goodsDialog from "@/components/goodsDialog";
export default {
  data() {
    return {
      activeMenu: 0,
      readmindVisible: false, // 网上预约需知弹窗
      goodsVisible: false,  // 商品弹窗
      menuList: [], // 左侧菜单栏
      goodsList: [],  // 右侧商品列表栏
      shopList: [],
    }
  },
  components: {
    footerPart,
    goodsDialog
  },
  created() {
    this.checkHasData();
    this.getMenuList();
  },
  mounted() {
    this.checkToken();
  },
  methods: {
    checkToken() {
      let getToken = this.$cookies.get("shopToken");
      if (!getToken) {
        sessionStorage.removeItem("valetOrder");
        // this.$cookies.remove("isCarList");
        this.$cookies.remove("shopToken");
        this.$cookies.remove("isRead");
      }
    },
    // 获取左侧菜单栏信息
    getMenuList() {
      this.$http.httpGet(`/mobile/onlineShop/getOnlineServiceTypeIdList`,
      ).then(data => {
        if (data.menuTreeList?.length) {
          this.menuList = data.menuTreeList;
          this.activeMenu = data.menuTreeList[0].id;
          this.getGoodsList(data.menuTreeList[0].id);
        } else {
          this.menuList = [];
          this.activeMenu = 0
        }
      })
    },
    // 获取商品列表信息
    getGoodsList(activeMenu) {
      this.$http.httpGet(`/mobile/onlineShop/getServiceByServiceTypeId`, {
        serviceTypeId: activeMenu
      }).then(data => {
        if (data.page?.length) {
          data.page.forEach(i => {
            if (i.imgUrl) {
              i.imgUrl = process.env.VUE_APP_UPLOAD_URL + i.imgUrl
            }
          })
        }
        this.goodsList = data.page;
      })
    },
    // 添加商品
    addOneNum(goods) {
      let token = this.$cookies.get("shopToken");
      if (token) {
        this.goodsList.forEach(item => {
          if (item.id === goods.id) {
            item.count += 1;
            if (item.count <= item.qty) {
              this.updataGoodNum(item.id,item.name,item.price,item.count,item.imgUrl)
            } else {
              this.$message.error("超出库存，无法下单更多商品");
              item.count -= 1;
            }
          }
        })
      } else {
        this.$message.error("请登录后进行操作")
      }
    },
    // 减少商品数量
    subtractNum(goods) {
      let token = this.$cookies.get("shopToken");
      if (token) {
        this.goodsList.forEach(item => {
          if (item.id === goods.id) {
            item.count -= 1;
            this.updataGoodNum(item.id,item.name,item.price,item.count,item.imgUrl)
            // this.$nextTick(() => {
            //   this.$refs.footerPart.getCarList();
            // })
          }
        })
      } else {
        this.$message.error("请登录后进行操作")
      }
    },
    updataGoodNum(id, name, price, count, imgUrl){
      this.$http.httpPost(`/mobile/onlineShop/updataGoodNum`, {
        id: id,
        name: name,
        price: price,
        count: count,
        imgUrl: imgUrl
      }).then(data => {
        if (data && data.code === 0) {
          //console.log("修改成功")
        } else {
          this.$message.error(data.msg);
        }
      });
    },
    // 检查是否返回，然后读取数据
    checkHasData() {
      let backRever = JSON.parse(sessionStorage.getItem("reverData"));
      if (backRever && backRever.length > 0) {
        this.reservateList = [];
        backRever.forEach(item => {
          let itemPart = {
            deathName: item.deathName, // 逝者姓名
            ashNum01: item.ashNum01, // 位置编号-楼
            ashNum02: item.ashNum02, // 位置编号-行
            ashNum03: item.ashNum03, // 位置编号-格
            relationship: item.relationship, // 与逝者关系
            contactsName: item.contactsName, // 寄存人姓名
            key: item.key //索引做区分
          }
          this.reservateList.push(itemPart);
        })
      }
    },
    // 菜单点击
    menuChange(id) {
      this.activeMenu = id;
      this.getGoodsList(this.activeMenu)
    },
    goodsClick(data) {
      this.goodsVisible = true;
      this.$nextTick(() => {
        this.$refs.goods.init(data);
      })
    }
  },
}
</script>

<style scoped lang="scss">
.sureDialog::v-deep {
  .el-dialog {
    margin: 10vh auto 0 !important;
    width: 90%;
    max-width: 600px;
    .el-dialog__header {
      padding: 15px;
      border-bottom: 1px #efefef solid;
      .el-dialog__title { font-size: 16px; line-height: 20px;}
      .el-dialog__headerbtn {top: 16px;}
    }
    .el-dialog__body {
      padding: 15px;
      max-height: calc(80vh - 51px - 61px - 30px);
      overflow-x: hidden;
      overflow-y: auto;
      p {
        font-size: 13px;
        color: #5e5e5e;
        line-height: 1.6;
        padding: 0;
        margin: 0;
      }
    }
    .el-dialog__footer {
      padding: 15px;
      border-top: 1px #efefef solid;
      .dialog-footer {
        .el-button {
          font-size: 12px;
          padding: 8px 20px;
        }
      }
    }
  }
}

.drawCheck::v-deep {
  .el-drawer {
    max-height: 60vh;
    height: auto !important;
    border-radius: 12px 12px 0 0;
    .el-drawer__header {
      display: none;
      opacity: 0;
    }
    .el-drawer__body {
      .drawerMain {
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        .drawerTil {
          position: relative;
          margin: 0;
          font-size: 14px;
          padding: 10px;
        }
        .drawListMain {
          position: relative;
          padding: 10px;
          overflow-x: hidden;
          overflow-y: auto;
          max-height: calc(60vh - 116px);
          .drawList {
            .el-collapse-item__header {
              border-bottom: none;
              line-height: 5vh;
              height: 5vh;
              .drawNameList {
                width: 100%;
                position: relative;
                overflow: hidden;
                display: flex;
                .drawDay, .drawWeek, .drawDate, .numstatus {
                  width: 60px;
                  font-size: 13px;
                  color: #333;
                }
                .numstatus {
                  text-align: right;
                  flex: 1;
                  padding: 0 6px 0 0;
                }
              }
            }
            .el-collapse-item__wrap {
              overflow-x: hidden;
              overflow-y: auto;

              .el-collapse-item__content {
                padding: 0;
                .timeBlock {
                  position: relative;
                  overflow-x: hidden;
                  overflow-y: auto;
                  .timeList {
                    width: calc(48% - 2px);
                    margin: 10px 1%;
                    border: 1px #efefef solid;
                    float: left;
                    display: flex;
                    border-radius: 6px;
                    line-height: 40px;
                    position: relative;
                    overflow: hidden;
                    .timeline {
                      font-size: 13px;
                      color: #333;
                      flex: 1;
                      text-align: center;
                      padding: 0;
                      border-radius: 6px 0 0 6px;
                    }

                    .numload {
                      width: 60px;
                      font-size: 16px;
                      text-align: center;
                      color: #fff;
                      border-radius: 0 6px 6px 0;
                      background-color: rgba(41, 143, 255, 1);
                    }
                  }

                  .finishTime {
                    .numload {
                      background-color: #efefef;
                      color: #6666;
                    }
                  }
                }
              }
            }
          }
        }

        .closeDrawBtn {
          position: relative;
          overflow: hidden;
          padding: 10px;
          .el-button {
            display: block;
            width: 100%;
          }
        }
      }
    }
  }
}

</style>
