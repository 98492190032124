<template>
  <el-dialog
    class="goodsDialog"
    :visible.sync="visible"
    title="商品详情"
    append-to-body
    top="10vh"
    destroy-on-close
  >
    <div class="goodModalPart">
      <div class="topImg">
        <img v-if="imgUrl" :src="imgUrl" style="width: 400px; height: 180px" alt="">
        <img v-if="!imgUrl" src="../views/assets/nodata.png" style="width: 180px;" alt="">
      </div>
      <div class="goodsDetail">
        <div class="goodsTil">{{ dataForm.name }}</div>
        <div class="goodsPrice">价格：<b>{{ dataForm.price }}</b></div>
        <div class="goodsDescriptionPart">
          <h5>商品介绍：</h5>
          <div class="goodsDescription">
            {{ dataForm.name }}
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      imgUrl: "",
      goodsData: {},
      dataForm: {
        name: null,
        id: 0,
        description: null,
        price: 0,
        imgUrlList:[]
      }
    }
  },
  methods: {
    init(data) {
      this.visible = true;
      this.goodsData = JSON.parse(JSON.stringify(data));
      this.dataForm = JSON.parse(JSON.stringify(data));
      console.log(this.goodsData)
      // console.log(this.goodsData)
      this.imgUrl = null;
      // console.log(this.imgUrl)
      if (this.goodsData.imgUrlList){
        this.imgUrl = process.env.VUE_APP_UPLOAD_URL + this.goodsData.imgUrlList[0];
        // console.log(this.imgUrl)
      }
    },
  }
}
</script>

<style lang="scss">
.goodsDialog {
  .el-dialog {
    width: 95%;
    .el-dialog__header {
      padding: 10px;
      border-bottom: 1px #efefef solid;
      .el-dialog__title {
        font-size: 15px;
      }
      .el-dialog__headerbtn {
        top: 13px;
        font-size: 18px;
      }
    }
    .el-dialog__body {
      padding: 0;
      max-height: calc(80vh - 46px);
      .goodModalPart {
        position: relative;
        .topImg {
          position: relative;
          overflow: hidden;
          height: 180px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            margin: 0 auto;
            display: block;
          }
        }
        .goodsDetail {
          position: relative;
          height: calc(80vh - 180px - 46px);
          overflow-x: hidden;
          overflow-y: overlay;
          padding: 15px 0;
          .goodsTil, .goodsPrice {
            font-size: 15px;
            line-height: 1.4;
            letter-spacing: 0.5px;
            color: #000;
            font-weight: 600;
            border-bottom: 1px #efefef solid;
            padding: 0 10px 15px;
          }
          .goodsPrice {
            padding: 10px;
            font-weight: 400;
            font-size: 13px;
            b {
              font-weight: 600;
              color: #f00;
              font-size: 15px;
            }
          }
          .goodsDescriptionPart {
            position: relative;
            padding: 15px 10px;
            h5 {
              font-size: 16px;
              padding: 0 0 15px;
              font-weight: 600;
              color: #000;
            }
            .goodsDescription {
              font-size: 13px;
              text-indent: 2em;
              line-height: 1.6;
              letter-spacing: 0.5px;
              color: #666;
            }
          }
        }
      }
    }
  }
}
</style>
